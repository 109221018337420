<template>
	<div class="layout">
		<main>
			<div class="layout-aside layout-aside--column">
				<div class="layout-aside__menu">
					<ChatMenu />
				</div>

				<div class="layout-aside__main">
					<slot />
				</div>
			</div>
		</main>
	</div>
</template>
